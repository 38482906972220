import * as Sentry from '@sentry/react';
import braintree from 'braintree-web';
import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import { useSetAtom } from 'jotai/index';
import { observer } from 'mobx-react-lite';
import type { FlowType } from 'paypal-checkout-components';
import { HTMLProps, ReactNode, useCallback, useEffect, useState } from 'react';
import PaypalLogo from 'src/assets/images/payment/paypal-logo.svg';
import LoadingIndicator from 'src/components/common/loading-indicator/loading-indicator';
import { useStores } from 'src/components/common/root-store-provider/root-store-provider';
import { PaymentMethod } from 'src/models/payments-store/payments-store';
import { useAnalytics } from '@features/Analytics';
import { AnalyticsPaymentMethod, AnalyticsPaymentSystem, PaymentCompletionStatus, braintreeClientTokenAtom, createBraintreeSubscription, handleCheckoutOpenRequest, usePaymentCompletionStatus } from '@features/Payments';
import { paymentMethodWithPersistence, selectedPlanAtom } from '@features/Stripe/atoms';
import styles from './paypal-button-braintree.module.scss';
const PAYPAL_BUTTONS_CONTAINER_ID = 'paypal-buttons-container';
export const PaypalButtonBraintree = observer(({
  className,
  subscriptionPlan,
  buttonContent = <PaypalLogo />,
  isCustomButton = false,
  id = PAYPAL_BUTTONS_CONTAINER_ID,
  handleAddPaymentInfoEventsSend,
  onLoadEnd,
  onSuccess,
  ...props
}: {
  onLoadEnd: () => void;
  handleAddPaymentInfoEventsSend: ({
    paymentMethod,
    paymentSystem
  }: {
    paymentSystem: 'stripe' | 'braintree';
    paymentMethod: string;
  }) => void;
  onSuccess: ({
    paymentMethod,
    paymentSystem
  }: {
    paymentMethod: string;
    paymentSystem: 'stripe' | 'braintree';
  }) => Promise<void>;
  subscriptionPlan: {
    merchantAccountId: string;
    braintreePlanId: string;
    fullPriceDiscount: number;
    id: string;
  };
  id?: string;
  isCustomButton?: boolean;
  buttonContent?: ReactNode;
} & Omit<HTMLProps<HTMLDivElement>, 'children'>) => {
  const {
    authStore,
    paymentsStore
  } = useStores();
  const {
    user,
    auth_token,
    variant
  } = authStore;
  const braintreeClientToken = useAtomValue(braintreeClientTokenAtom);
  const {
    introOfferData
  } = useAtomValue(selectedPlanAtom);
  const {
    trackFacebookEvent,
    trackGoogleEvent,
    trackPinterestEvent
  } = useAnalytics();
  const {
    setPaymentCompletionStatus
  } = usePaymentCompletionStatus();
  const setPaymentMethod = useSetAtom(paymentMethodWithPersistence);
  const [isLoading, setIsLoading] = useState(true);
  const [buttonRendered, setButtonRendered] = useState(false);
  const braintreeHandle = useCallback(async () => {
    setIsLoading(true);
    setButtonRendered(true);
    if (!user) {
      throw new Error('A user is required');
    }
    if (!braintreeClientToken) {
      throw new Error('Client token is required');
    }
    const client = await braintree.client.create({
      authorization: braintreeClientToken
    });
    const paypalCheckout = await braintree.paypalCheckout.create({
      client
    });
    await paypalCheckout.loadPayPalSDK({
      vault: true
    });
    const buttons = window.paypal.Buttons({
      style: {
        height: 48
      },
      // @ts-ignore (an issue with library typings)
      fundingSource: window.paypal.FUNDING.PAYPAL,
      createBillingAgreement: () => {
        paymentsStore.setPaymentMethod(PaymentMethod.PAYPAL);
        handleCheckoutOpenRequest({
          subscriptionPlan: {
            id: subscriptionPlan.id,
            fullPriceDiscount: subscriptionPlan.fullPriceDiscount
          },
          paymentMethod: AnalyticsPaymentMethod.PAYPAL,
          paymentSystem: AnalyticsPaymentSystem.BRAINTREE,
          email: user!.email,
          variant: variant
        });
        trackFacebookEvent({
          eventName: 'AddPaymentInfo',
          customData: {
            paymentMethod: 'payPal',
            paymentSystem: 'braintree'
          },
          options: {
            email: user?.email
          }
        });
        trackPinterestEvent({
          eventName: 'AddToCart'
        });
        return paypalCheckout.createPayment({
          flow: ('vault' as (typeof FlowType)['Vault'])
        });
      },
      onCancel: () => {
        setIsLoading(false);
        trackGoogleEvent({
          eventName: 'add_payment_info_skipped',
          options: {
            payment_system: 'braintree',
            payment_method: AnalyticsPaymentMethod.PAYPAL
          }
        });
      },
      onApprove: async data => {
        setIsLoading(true);
        try {
          const authorizationResponse = await paypalCheckout.tokenizePayment(data);
          await createBraintreeSubscription({
            customerId: user!.id.toString(),
            customerToken: auth_token!,
            merchantAccountId: subscriptionPlan.merchantAccountId,
            planId: subscriptionPlan.braintreePlanId!,
            paymentMethodNonce: authorizationResponse.nonce,
            isStripePAth: true,
            btIntroOfferAmount: introOfferData?.fullPriceDiscount,
            btIntroOfferCurrency: introOfferData?.currency
          });
          setPaymentMethod('paypal');
          await onSuccess({
            paymentMethod: 'paypal',
            paymentSystem: 'braintree'
          });
          return authorizationResponse;
        } finally {
          setIsLoading(false);
        }
      },
      onClick: () => {
        setIsLoading(true);
        handleAddPaymentInfoEventsSend({
          paymentSystem: 'braintree',
          paymentMethod: 'paypal'
        });
      },
      onError: error => {
        setIsLoading(false);
        const errorPhrase = /Error: Detected popup close/ || /Error: zoid destroyed all components/ || /Error: Window is closed/;
        if (errorPhrase.test(error)) {
          console.log('detected popup close');
          return;
        }
        Sentry.captureException(error);
        trackGoogleEvent({
          eventName: 'payment_error',
          options: {
            errorCode: error,
            errorDeclineCode: '',
            paymentMethod: 'paypal',
            paymentSystem: 'Braintree'
          }
        });
        setPaymentCompletionStatus({
          value: PaymentCompletionStatus.FAILURE,
          selectedPlanId: subscriptionPlan.id
        });
      }
    });
    if (document.querySelector(`#${PAYPAL_BUTTONS_CONTAINER_ID}`) && !buttonRendered) {
      setButtonRendered(true);
      buttons.render(`#${PAYPAL_BUTTONS_CONTAINER_ID}`);
    }
    setIsLoading(false);
    onLoadEnd();
  }, [user, braintreeClientToken, buttonRendered, onLoadEnd, paymentsStore, subscriptionPlan.id, subscriptionPlan.fullPriceDiscount, subscriptionPlan.merchantAccountId, subscriptionPlan.braintreePlanId, variant, trackFacebookEvent, trackPinterestEvent, trackGoogleEvent, auth_token, introOfferData?.fullPriceDiscount, introOfferData?.currency, setPaymentMethod, onSuccess, handleAddPaymentInfoEventsSend, setPaymentCompletionStatus]);
  useEffect(() => {
    if (!buttonRendered && braintreeClientToken !== '') {
      braintreeHandle().then(r => r);
    }
  }, [braintreeClientToken, braintreeHandle, buttonRendered]);
  return <div className={classNames(styles.container, className)} {...props}>
        {isLoading ? <div className={styles.loaderContainer}>
            <LoadingIndicator />
          </div> : buttonContent}
        <div className={styles.frame} style={{
      opacity: isCustomButton ? 0.0001 : 1
    }} id={id} />
      </div>;
});