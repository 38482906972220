import { UpsellModalComponentKey } from '@features/Stripe/widgets/upsell-modal-renderer';

export const minimalInitialModalState: TpMinimalModalState = {
  currentModal: null,
  wasShown: {},
};

export type TpMinimalModalState = {
  currentModal: UpsellModalComponentKey | null;
  wasShown: any;
};

export const minimalActionTypes = {
  SHOW_MODAL: 'SHOW_MODAL',
  HIDE_MODAL: 'HIDE_MODAL',
} as const;

export type TpMinimalActionType = keyof typeof minimalActionTypes;
export type TpMinimalModalAction = {
  type: TpMinimalActionType;
  payload: { modalId: UpsellModalComponentKey | null };
};

export const stripeMinimalModalReducer = (
  state: TpMinimalModalState,
  action: TpMinimalModalAction,
) => {
  switch (action.type) {
    case minimalActionTypes.SHOW_MODAL:
      return {
        ...state,
        currentModal: action.payload.modalId,
        wasShown:
          action.payload.modalId !== null
            ? { ...state.wasShown, [action.payload.modalId]: true }
            : state.wasShown,
      };

    case minimalActionTypes.HIDE_MODAL:
      return {
        ...state,
        currentModal: null,
      };

    default:
      return state;
  }
};
