import classNames from 'classnames';
import ImageTag from 'next/legacy/image';
import type { TpImageProps } from 'src/components/common/Image';
import { ICON_SOURCE } from 'src/utils/constants';
const customLoader = ({
  src
}: {
  src: string;
}) => `${ICON_SOURCE}${src}`;
export const SimpleImage = (props: TpImageProps) => {
  const {
    src,
    className,
    ...otherProps
  } = props;
  return <span className={classNames(className)} data-sentry-component="SimpleImage" data-sentry-source-file="index.tsx">
      <ImageTag src={src} loader={customLoader} {...otherProps} data-sentry-element="ImageTag" data-sentry-source-file="index.tsx" />
    </span>;
};