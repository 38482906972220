import { FormattedMessage } from "@features/intl";
import styles from "./upsell-yearly-price-per-day.module.scss";
type TpUpsellYearlyPricePerDayProps = {
  currency: string;
  discount?: number;
  pricePerDay: number;
};
const DiscountBadge = ({
  discount
}: {
  discount: number;
}) => {
  return <div className={styles.discountBadge} data-discountPercantage={discount} data-sentry-component="DiscountBadge" data-sentry-source-file="index.tsx">
      <svg xmlns="http://www.w3.org/2000/svg" width="53" height="52" viewBox="0 0 53 52" fill="none" data-sentry-element="svg" data-sentry-source-file="index.tsx">
        <path d="M26.5 0L29.5801 3.59068L33.5147 0.964151L35.5118 5.25268L40.0092 3.7851L40.7752 8.45341L45.5017 8.25362L44.9799 12.9555L49.585 14.0383L47.8139 18.425L51.9562 20.7101L49.0673 24.4564L52.4394 27.7743L48.6469 30.6022L50.9988 34.7069L46.584 36.4067L47.7412 40.9937L43.0315 41.4394L42.9083 46.1685L38.253 45.327L36.8584 49.8475L32.6028 47.7812L30.0403 51.7578L26.5 48.62L22.9597 51.7578L20.3972 47.7812L16.1416 49.8475L14.747 45.327L10.0917 46.1685L9.96849 41.4394L5.25878 40.9937L6.41604 36.4067L2.00122 34.7069L4.35312 30.6022L0.560612 27.7743L3.93273 24.4564L1.04381 20.7101L5.18606 18.425L3.41498 14.0383L8.02014 12.9555L7.49826 8.25362L12.2248 8.45341L12.9908 3.7851L17.4882 5.25268L19.4853 0.964151L23.4199 3.59068L26.5 0Z" fill="#FFB800" data-sentry-element="path" data-sentry-source-file="index.tsx" />
      </svg>
    </div>;
};
export const UpsellYearlyPricePerDay = ({
  currency,
  discount,
  pricePerDay
}: TpUpsellYearlyPricePerDayProps) => {
  const integerPrice = Math.floor(Number(pricePerDay));
  const decimalPrice = Math.round((Number(pricePerDay) - Math.floor(Number(pricePerDay))) * 100).toString().padStart(2, '0');
  return <div className={styles.pricePerDayBlock} data-sentry-component="UpsellYearlyPricePerDay" data-sentry-source-file="index.tsx">
      <span className={styles.plusSign}>+</span>
      <span className={styles.currencySymbol}>{currency}</span>
      {integerPrice}
      <span className={styles.priceInCents}>
        {decimalPrice}
        <span className={styles.perDayLabel}>
          <FormattedMessage defaultMessage="per" id="Onboarding.PremiumTotal.per" data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />{' '}
          <FormattedMessage defaultMessage="day" id="Onboarding.PremiumTotal.perday" data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
        </span>
      </span>
      {discount && <DiscountBadge discount={discount} />}
    </div>;
};