import { FormattedMessage } from "@features/intl";
import styles from "./upsell-yearly-title.module.scss";
export const UpsellYearlyTitle = () => {
  return <h1 className={styles.title} data-sentry-component="UpsellYearlyTitle" data-sentry-source-file="index.tsx">
      <FormattedMessage defaultMessage="Looking to make the" id="Onboarding.PremiumTotal.Title1" data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
      <br />
      <FormattedMessage defaultMessage="changes {mark1} {br} {mark2}" id="Onboarding.PremiumTotal.Title2" values={{
      mark1: <mark>
              <FormattedMessage id="Onboarding.PremiumTotal.Mark1" defaultMessage="46% more" />
            </mark>,
      br: <br />,
      mark2: <mark>
              <FormattedMessage id="Onboarding.PremiumTotal.Mark2" defaultMessage="successful?" />
            </mark>
    }} data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
    </h1>;
};