import { reaction } from 'mobx';
import { useEffect, useState } from 'react';

import { useStores } from 'src/components/common/root-store-provider/root-store-provider';

export const useIsAutoRenewEnabled = () => {
  const {
    authStore: { variant },
  } = useStores();

  const [isAutoRenewEnabled, setIsAutoRenewEnabled] = useState(() =>
    ['variant20', 'variant21', 'variant22'].includes(variant ?? ''),
  );

  useEffect(() => {
    const dispose = reaction(
      () => variant,
      (variant) => {
        setIsAutoRenewEnabled(
          ['variant20', 'variant21', 'variant22'].includes(variant ?? ''),
        );
      },
      { fireImmediately: true },
    );

    return () => dispose();
  }, [variant]);

  return isAutoRenewEnabled;
};
