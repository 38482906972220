import { currencyReplacer } from '@features/Payments';
import { memo } from 'react';
import styles from './separated-price.module.scss';
type TpSeparatedPrice = {
  currency: string;
  price: number;
};
export const SeparatedPrice = memo(({
  currency,
  price
}: TpSeparatedPrice) => {
  const currencySymbol = currencyReplacer(currency);
  const integerPrice = Math.floor(Number(price));
  const decimalPrice = Math.round((Number(price) - Math.floor(Number(price))) * 100).toString().padStart(2, '0');
  return <p className={styles.price}>
      <span className={styles.currencySymbol}>{currencySymbol}</span>
      {integerPrice}
      <span className={styles.priceInCents}>{decimalPrice}</span>
    </p>;
});