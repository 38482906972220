import { UpsellAgreeAndDeclineButtons } from "@features/Upsell/components/upsell-agree-and-decline-buttons";
import { UpsellYearlyCoffeeCup } from "@features/Upsell/components/upsell-yearly-coffee-cup";
import { UpsellYearlyDescription } from "@features/Upsell/components/upsell-yearly-description";
import { UpsellYearlyGraph } from "@features/Upsell/components/upsell-yearly-graph";
import { UpsellYearlyPricePerDay } from "@features/Upsell/components/upsell-yearly-price-per-day";
import { UpsellYearlyTitle } from "@features/Upsell/components/upsell-yearly-title";
import { memo, useCallback, useEffect } from "react";
import { Image } from "src/components/common/Image";
import Modal from "src/components/common/modal/modal/modal";
import { useAnalytics } from "@features/Analytics";
import { currencyReplacer } from "@features/Payments";
import { premiumYearAllDataAtom, selectedPlanAtom, upsellInfoAtom, userAtom } from "@features/Stripe/atoms";
import { useModifySubscription } from "@features/Stripe/hooks/mutation/use-modify-subscription";
import { UpsellSharedModalProps } from "@features/Stripe/widgets/upsell-modal-renderer";
import { FormattedMessage } from "@features/intl";
import { useAtomValue } from "jotai/index";
import { replaceDecimalsWithNines } from "src/utils/replace-decimals-with-nines";
import styles from "./upsell-extra-discount-yearly-premium.module.scss";
export const UpsellExtraYearlyDiscountPremium = memo(({
  onNext
}: UpsellSharedModalProps) => {
  const {
    mutateAsync: modifySubscription
  } = useModifySubscription();
  const {
    currency,
    premiumDiscountPriceData,
    fullPriceDiscount: currentFullPriceDiscount
  } = useAtomValue(selectedPlanAtom);
  const {
    fullPriceDiscount: ebookPrice
  } = useAtomValue(upsellInfoAtom);
  const {
    email,
    userId
  } = useAtomValue(userAtom);
  const {
    premiumYearDiscountData
  } = useAtomValue(premiumYearAllDataAtom);
  const {
    trackGoogleEvent
  } = useAnalytics();
  const scannerPrice = replaceDecimalsWithNines(ebookPrice * 4);
  const currencySymbol = currencyReplacer(currency);
  const yearlyPlanFakePrice = (Number(premiumYearDiscountData?.fullPriceDiscount) - Number(premiumDiscountPriceData?.fullPriceDiscount)).toFixed(2);
  const upgradedPricePerDay = ((Number(premiumYearDiscountData?.fullPriceDiscount) - Number(currentFullPriceDiscount)) / 365).toFixed(2);
  const benefitPrice = Number(premiumYearDiscountData.fullPrice) - Number(premiumYearDiscountData.fullPriceDiscount);
  const decimalYearPrice = Math.round((Number(premiumYearDiscountData.fullPriceDiscount) - Math.floor(Number(premiumYearDiscountData.fullPriceDiscount))) * 100).toString().padStart(2, "0");
  const handleBuy = useCallback(() => {
    trackGoogleEvent({
      eventName: "looking-to-increase-extra_button_try-premium_click"
    });
    if (premiumYearDiscountData?.upgradeId) {
      modifySubscription({
        email,
        userId,
        newPlanId: premiumYearDiscountData?.upgradeId,
        paymentSystem: "stripe"
      }).then(r => r);
      onNext("SUCCEED");
    }
  }, [onNext, modifySubscription, email, userId, premiumYearDiscountData?.upgradeId, trackGoogleEvent]);
  const handleDecline = useCallback(() => {
    trackGoogleEvent({
      eventName: "looking-to-increase-extra_button_decline_click"
    });
    onNext("MODAL_UPSELL");
  }, [onNext, trackGoogleEvent]);
  useEffect(() => {
    trackGoogleEvent({
      eventName: "looking-to-increase-extra_screen__loaded"
    });
  }, [trackGoogleEvent]);
  return <Modal className={styles.container} show={true} showCloseButton={false} showMobileHeader={false}>
        <div>
          <UpsellYearlyTitle />

          <UpsellYearlyDescription />

          <UpsellYearlyGraph />

          <UpsellYearlyPricePerDay currency={currencySymbol} pricePerDay={Number(upgradedPricePerDay)} discount={15} />

          <UpsellYearlyCoffeeCup />

          <div className={styles.billSection}>
            <div className={styles.billItem}>
              <p className={styles.billItemLabel}>
                <FormattedMessage defaultMessage="Plan enhancements" id="Onboarding.PremiumTotal.Line1" />
              </p>
              <p className={styles.billItemPrice}>
                <del>
                  {currencySymbol}
                  {scannerPrice}
                </del>
                {currencySymbol}0
              </p>
            </div>
            <div className={styles.billItem}>
              <p className={styles.billItemLabel}>
                <FormattedMessage defaultMessage="Yearly Plan" id="Onboarding.PremiumTotal.Line2" />
              </p>
              <p className={styles.billItemPrice}>
                {currencySymbol}
                {yearlyPlanFakePrice}
              </p>
            </div>
            <div className={styles.billItem}>
              <p className={styles.billItemLabel}>
                <FormattedMessage defaultMessage="Premium" id="Onboarding.PremiumTotal.Line3" />
              </p>
              <p className={styles.billItemPrice}>
                {currencySymbol}
                {premiumDiscountPriceData?.fullPriceDiscount.toFixed(2)}
              </p>
            </div>

            <div className={styles.bonus}>
              <p className={styles.bonusLabel}>
                <FormattedMessage defaultMessage="Bonus:" id="Onboarding.PremiumFinal.Bonus1" />
              </p>
              <div className={styles.bonusItem}>
                <div className={styles.bonusItemLabel}>
                  <Image src="wrapped-gift-emoji.webp" width={16} height={16} alt="" />
                  <FormattedMessage id="Onboarding.PremiumFinal.Bonus2" defaultMessage="Extra bonus:" />
                </div>
                <p className={styles.billItemDiscountPercentage}>-15%</p>
              </div>
            </div>

            <div className={styles.billTotal}>
              <p className={styles.billTotalBenefitBadge}>
                <span>
                  <FormattedMessage defaultMessage="Your benefit {mark}" id="Onboarding.PremiumFinal.Yourbenefit" values={{
                mark: <>
                          &nbsp;
                          <b>
                            {currencySymbol}
                            {benefitPrice}
                          </b>
                        </>
              }} />
                </span>
              </p>
              <p className={styles.billTotalLabel}>
                <FormattedMessage defaultMessage="Total today:" id="Onboarding.PremiumFinal.Total" />
              </p>
              <p className={styles.billTotalPriceBlock}>
                <div className={styles.billTotalCurrentPrice}>
                  <span className={styles.currentPrice}>
                    {currencySymbol}
                    {premiumYearDiscountData.fullPriceDiscount.toFixed(0)}
                  </span>
                  <span className={styles.currentPriceCents}>
                    {decimalYearPrice}
                  </span>
                </div>
                <div className={styles.billTotalOldPrice}>
                  <span>
                    <FormattedMessage defaultMessage="was" id="Onboarding.PremiumFinal.Was" />
                  </span>
                  <del>
                    {currencySymbol}
                    {premiumYearDiscountData.fullPrice.toFixed(2)}
                  </del>
                </div>
              </p>
            </div>
          </div>

          <UpsellAgreeAndDeclineButtons testIdBuyButton="yearly-premium-extra-buy-button" testIdDeclineButton="yearly-premium-extra-decline-button" className={styles.buttonsContainer} handleDecline={handleDecline} handleBuy={handleBuy} />
        </div>
      </Modal>;
});
UpsellExtraYearlyDiscountPremium.displayName = 'UpsellExtraYearlyDiscountPremium';