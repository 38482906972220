import { Image } from 'src/components/common/Image';
import { FormattedMessage } from 'src/features/intl';
import styles from './community.module.scss';
export const Community = () => <div className={styles.wrapper} data-sentry-component="Community" data-sentry-source-file="index.tsx">
    <h4>
      <FormattedMessage defaultMessage="Join Mimika Community" id="Onboarding.Paywall.Main.Map.Title1" data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
    </h4>

    <Image className={styles.imageMap} src="community-map-v19.webp" width={295} height={194} priority alt="map" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
    <h4>
      <FormattedMessage id="Onboarding.Paywall.Main.Map.Title2" defaultMessage="2+ million users" data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
    </h4>
  </div>;