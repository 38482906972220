import classNames from 'classnames';
import { ReactNode, memo } from 'react';
import { UiKitCheckbox } from 'src/components/common/ui-kit-checkbox';
import { PolicyLink, RefundLink, TermsLink } from 'src/widgets/policy';
import { FormattedMessage } from '@features/intl';
import styles from './privacy-agreement.module.scss';
const DefaultAgreementText = () => {
  return <FormattedMessage id="Onboarding.Paywall.Main.Terms" defaultMessage="By purchasing, you agree to our {privacy}, {terms}, {mark} {subscription}" values={{
    privacy: <PolicyLink place="paywall" />,
    terms: <TermsLink place="paywall" />,
    subscription: <RefundLink place="paywall" />,
    mark: <span>
            <FormattedMessage defaultMessage="and" id="Onboarding.Paywall.Main.Terms.Mark" />
          </span>
  }} data-sentry-element="FormattedMessage" data-sentry-component="DefaultAgreementText" data-sentry-source-file="index.tsx" />;
};
export const PrivacyAgreement = memo(({
  isChecked,
  onChecked,
  agreementText = <DefaultAgreementText />,
  className
}: {
  isChecked: boolean;
  onChecked: () => void;
  agreementText?: ReactNode;
  className?: string;
}) => {
  const randomId = 'subscriptionAgreement' + Math.random().toString();
  return <div className={classNames(styles.subscriptionAgreement, className)}>
        <UiKitCheckbox checked={isChecked} label={agreementText} labelClassName={classNames(!isChecked && styles.dangerAgreement)} value={randomId} onChange={onChecked} id={randomId} />
      </div>;
});
PrivacyAgreement.displayName = 'PrivacyAgreement';