import { useCallback, useMemo } from 'react';

import { retry } from 'src/utils';

import { TpGoogleParameters } from '@features/Analytics/types';

export const useGoogleEvent = () => {
  const conditionCheckFunction = useCallback(
    async () => window?.gtag != null,
    [],
  );

  const fetchClientEventWithRetry = useCallback(
    async ({ eventName, options }: TpGoogleParameters) => {
      const actionFunction = async () => {
        window?.gtag?.('event', eventName, options);
      };

      await retry({
        conditionCheckFunction,
        actionFunction,
        retryDelay: 300,
      });
    },
    [conditionCheckFunction],
  );

  return useMemo(
    () => ({
      clientEvent: ({ eventName, options }: TpGoogleParameters) => {
        fetchClientEventWithRetry({
          eventName,
          options,
        });
      },
    }),
    [fetchClientEventWithRetry],
  );
};
