import { SeparatedPrice } from "@features/Upsell/components/separated-price";
import { memo } from "react";
import { Image } from "src/components/common/Image";
import { FormattedMessage } from "@features/intl";
import dayjs from "dayjs";
import { secondsToDays } from "src/utils/conver-seconds-to-days";
import styles from "./now-and-later-premium-prices.module.scss";
type TpNowAndLaterPremiumPricesProps = {
  currency: string;
  currentPrice: number;
  featurePrice: number;
  premiumDayDuration: string;
};
export const NowAndLaterPremiumPrices = memo(({
  currency,
  currentPrice,
  featurePrice,
  premiumDayDuration
}: TpNowAndLaterPremiumPricesProps) => {
  const convertedPremiumDayDuration = secondsToDays(premiumDayDuration);
  const featureDate = dayjs().add(convertedPremiumDayDuration, 'day').format('MMMM DD');
  return <div className={styles.priceContainer}>
        <Image src="upsell-price-divider.webp" alt="" width={12} height={67} />

        <div className={styles.nowAndAfterPricesContainer}>
          <div className={styles.labelAndPriceContainer}>
            <p className={styles.billingDateLabel}>
              <FormattedMessage defaultMessage="Due today" id="Onboarding.Premium.DueToday" />
            </p>
            <SeparatedPrice currency={currency} price={currentPrice} />
          </div>

          <hr />

          <div className={styles.labelAndPriceContainer}>
            <p className={styles.billingDateLabel}>
              <FormattedMessage id="Onboarding.Premium.DueDate" defaultMessage="Due on {mark}" values={{
            mark: featureDate
          }} />
            </p>
            <SeparatedPrice currency={currency} price={featurePrice} />
          </div>
        </div>
      </div>;
});