import { useAtomValue } from 'jotai';
import { memo, useCallback, useEffect } from 'react';
import { FormattedNumber } from 'react-intl';
import Alert from 'src/components/common/modal/alert/alert';
import { UiKitButton } from 'src/components/common/ui-kit-button';
import { useAnalytics } from '@features/Analytics';
import { currencyReplacer } from '@features/Payments';
import { paymentMethodWithPersistence, selectedPlanAtom, stripeWasPaymentTriedAtom, upgradeInfoAtom, userAtom } from '@features/Stripe/atoms';
import { useGetUpgradeData } from '@features/Stripe/hooks';
import { useModifySubscription } from '@features/Stripe/hooks/mutation/use-modify-subscription';
import { FormattedMessage } from '@features/intl';
import { UpsellSharedModalProps } from '@features/Stripe/widgets/upsell-modal-renderer';
import ChallengesImage from '../../../../../public/images/upsell-challenges.svg';
import HabitsImage from '../../../../../public/images/upsell-habits-development.svg';
import HealthyImage from '../../../../../public/images/upsell-healthy-programs.svg';
import LifeChangingImage from '../../../../../public/images/upsell-life-changing-results.svg';
import styles from './limited-upsell.module.scss';
export const LimitedUpsell = memo(({
  onNext
}: UpsellSharedModalProps) => {
  const {
    mutateAsync: modifySubscription
  } = useModifySubscription();
  const {
    fullPriceDiscount: currentFullPriceDiscount
  } = useAtomValue(selectedPlanAtom);
  const {
    discountPercentage,
    currency,
    fullPriceDiscount,
    braintreePlanId,
    upgradeId,
    fullPrice: fullPriceUpgrade
  } = useAtomValue(upgradeInfoAtom);
  const {
    data: upgradedData
  } = useGetUpgradeData({
    fullPrice: currentFullPriceDiscount,
    upgradePrice: fullPriceDiscount
  });
  const {
    showCrossSailOffer
  } = useAtomValue(stripeWasPaymentTriedAtom);
  const paymentMethod = useAtomValue(paymentMethodWithPersistence);
  const isPayedWithBraintreePayPal = paymentMethod === 'paypal';
  const {
    trackGoogleEvent
  } = useAnalytics();
  const {
    email,
    userId
  } = useAtomValue(userAtom);
  const handleBuy = useCallback(() => {
    modifySubscription({
      email,
      userId,
      newPlanId: isPayedWithBraintreePayPal ? braintreePlanId : upgradeId,
      paymentSystem: isPayedWithBraintreePayPal ? 'braintree' : 'stripe'
    }).then(r => r);
    onNext('MODAL_UPSELL');
    trackGoogleEvent({
      eventName: 'upgrade_chose',
      options: {
        email,
        upgradeId: upgradeId,
        fullPrice: fullPriceUpgrade
      }
    });
  }, [email, isPayedWithBraintreePayPal, onNext, modifySubscription, trackGoogleEvent, braintreePlanId, fullPriceUpgrade, upgradeId, userId]);
  const handleDecline = useCallback(() => {
    onNext('MODAL_UPSELL');
    trackGoogleEvent({
      eventName: 'upgrade_decline',
      options: {
        email,
        upgradeId: upgradeId,
        fullPrice: fullPriceUpgrade
      }
    });
  }, [email, onNext, trackGoogleEvent, fullPriceUpgrade, upgradeId]);
  useEffect(() => {
    if (showCrossSailOffer && currentFullPriceDiscount !== 0) {
      trackGoogleEvent({
        eventName: 'upgrade_open',
        options: {
          email,
          upgradeId: upgradeId,
          fullPrice: fullPriceUpgrade
        }
      });
    }
  }, [email, currentFullPriceDiscount, showCrossSailOffer, trackGoogleEvent, fullPriceUpgrade, upgradeId]);
  return <Alert className={styles.container} show={true}>
      <div>
        <h2 className={styles.title}>
          Upgrade to an annual <br />
          <mark>Limited Plus+</mark>
          Program
        </h2>

        <ul className={styles.list}>
          <li className={styles.listItem}>
            <LifeChangingImage />
            <span className={styles.listTitle}>Life-changing results</span>
          </li>
          <li className={styles.listItem}>
            <HabitsImage />
            <span className={styles.listTitle}>Habits development</span>
          </li>
          <li className={styles.listItem}>
            <ChallengesImage />
            <span className={styles.listTitle}>Challenges</span>
          </li>
          <li className={styles.listItem}>
            <HealthyImage />
            <span className={styles.listTitle}>Healthy programs</span>
          </li>
        </ul>

        <h2 className={styles.subtitle}>
          Limited offer{' '}
          <mark className={styles.offMark}>-{discountPercentage}% off</mark>
        </h2>

        <div>
          <div className={styles.priceBlock}>
            <span className={styles.priceBlockSubTitle}>Your current plan</span>
            <span className={styles.sign}>+</span>
            <sup className={styles.currency}>{currencyReplacer(currency)}</sup>
            {upgradedData?.surchargePerDay !== null && <p className={styles.surchargePerDay}>
                {Number(upgradedData?.surchargePerDay).toString()[0]}{' '}
                <sup>
                  {Number(upgradedData?.surchargePerDay).toFixed(2).toString().slice(2)}
                </sup>
              </p>}
            <span className={styles.period}>per day</span>
          </div>
        </div>

        <p className={styles.cupContainer}>Less than a cup of coffee</p>

        <UiKitButton fullWidth className={styles.payButton} onClick={handleBuy}>
          Upgrade
        </UiKitButton>

        <UiKitButton as="a" color="pure" className={styles.declineButton} onClick={handleDecline}>
          <FormattedMessage id="Onboarding.Book.Button.Decline" defaultMessage="Decline an offer" />
        </UiKitButton>

        <div className={styles.additionalDescription}>
          <p>
            The Mimika Plus+ Subscription is an extended annual subscription.
            Today, you will receive a {discountPercentage}% discount on the full
            subscription price, meaning it will be charged{' '}
            <FormattedNumber value={Number(Number(upgradedData?.surcharge).toFixed(2))} style="currency" currency={currency} />
            . Then, when this subscription ends, payment will be made at the
            full subscription price of{' '}
            <FormattedNumber value={upgradedData?.upgradePricePerMonth ?? 0} style="currency" currency={currency} />
            /month ( {fullPriceDiscount} per year). Billing is annual. You can
            cancel your subscription at any time.
          </p>
          <p>
            By purchasing, you agree to the T&Cs, Privacy Policy, Money Back,
            and Cancelation Policy
          </p>
          <p>
            Mimika will use your payment details for seemingless future
            payments. The transaction will be converted into USD based on your
            bank’s exchange rate. Subscriptions renew automatically at the end
            of each period unless you cancel at least 24 hours before the end of
            the current period. To avoid being charged, cancel your subscription
            by contacting our support at least 24 hours before the end of the
            paid period. If you are unsure how to cancel, please contact our
            support via support@mimika-app. You may want to make a screenshot of
            this information for your reference.
          </p>
        </div>
      </div>
    </Alert>;
});
LimitedUpsell.displayName = 'LimitedUpsell';