import { useShouldInit } from '@features/Analytics/hooks';
import { observer } from 'mobx-react-lite';
import Script from 'next/script';
import { useEffect } from 'react';
import useCookie from 'react-use-cookie';
import { useStores } from 'src/components/common/root-store-provider/root-store-provider';
import { GA_ID, GA_ID_V2, GTM_ID } from '../../constants';
export const GoogleAnalyticsComponent = observer(({
  shouldSecondGaInit
}: {
  shouldSecondGaInit: boolean;
}) => {
  const {
    authStore: {
      variant,
      setGooglePseudoID
    }
  } = useStores();
  const {
    shouldGoogleAnalyticsV2Init
  } = useShouldInit(variant);
  const [googlePseudoId] = useCookie('_ga', '0');
  useEffect(() => {
    setGooglePseudoID(googlePseudoId.slice(6));
  }, [googlePseudoId, setGooglePseudoID]);
  return <>
        <Script async src={`https://www.googletagmanager.com/gtag/js?id=${shouldSecondGaInit ? GA_ID_V2 : GA_ID}`} />
        <Script id="gtag">
          {`window.dataLayer = window.dataLayer || [];
            function gtag(){ dataLayer.push(arguments); }
            gtag('js', new Date());
            gtag('consent', 'default', {
              'ad_storage': 'granted'
            });
          gtag('config', ${JSON.stringify(shouldSecondGaInit ? GA_ID_V2 : GA_ID)}, {
            page_path: window.location.pathname
          });`}
        </Script>
      </>;
});
export const GoogleTagManager = () => <Script id="gtm_id" data-sentry-element="Script" data-sentry-component="GoogleTagManager" data-sentry-source-file="index.tsx">
    {`
          (function(w,d,s,l,i){
            w[l]=w[l]||[];
            w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
            var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
            j.async=true;
            j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
            f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer', ${JSON.stringify(GTM_ID)});
        `}
  </Script>;