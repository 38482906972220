import { currencyReplacer } from "@features/Payments";
import { SeparatedPrice } from "@features/Upsell/components/separated-price";
import { FormattedMessage } from "@features/intl";
import classnames from "classnames";
import CheckMark from "src/assets/images/payment/check-icon.svg";
import { secondsToDays } from "src/utils/conver-seconds-to-days";
import styles from "./premium-plans-compare-section.module.scss";
type TpPremiumPlansCompareSectionProps = {
  trialDays: string;
  currency: string;
  periodUnit: string;
  currentPrice: number;
  premiumCurrentPrice: number;
  premiumOldPrice: number;
  isExtraDiscount?: boolean;
};
const FEATURES = [{
  name: <FormattedMessage id="Onboarding.Upsale.Compare1" defaultMessage="Skincare program" />,
  includedInCurrent: true,
  includedInPremium: true
}, {
  name: <FormattedMessage id="Onboarding.Upsale.Compare2" defaultMessage="Weekly progress {br} updates" values={{
    br: <br />
  }} />,
  includedInCurrent: false,
  includedInPremium: true
}, {
  name: <FormattedMessage id="Onboarding.Upsale.Compare3" defaultMessage="Healthy programs for {br} every occasion" values={{
    br: <br />
  }} />,
  includedInCurrent: false,
  includedInPremium: true
}, {
  name: <FormattedMessage id="Onboarding.Upsale.Compare4" defaultMessage="Reports on progress {br} analysis" values={{
    br: <br />
  }} />,
  includedInCurrent: false,
  includedInPremium: true
}];
export const PremiumPlansCompareSection = ({
  trialDays,
  currency,
  periodUnit,
  currentPrice,
  premiumCurrentPrice,
  premiumOldPrice,
  isExtraDiscount = false
}: TpPremiumPlansCompareSectionProps) => {
  const convertedTrialDays = secondsToDays(trialDays);
  return <div className={styles.plansCompareSection} data-sentry-component="PremiumPlansCompareSection" data-sentry-source-file="index.tsx">
      <h2 className={styles.plansCompareSectionTitle}>
        <FormattedMessage id="Onboarding.Upsale.Subtitle1" defaultMessage="Try {mark}" values={{
        mark: <mark>
                <FormattedMessage defaultMessage="Premium" id="Onboarding.Upsale.Subtitle1.Mark1" />
              </mark>
      }} data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
        &nbsp;
        <FormattedMessage defaultMessage="today" id="Onboarding.Upsale.Subtitle2" data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
        <br />
        <FormattedMessage defaultMessage="with a {mark1} {mark2}" id="Onboarding.Upsale.Subtitle3" values={{
        mark1: <mark>{convertedTrialDays}</mark>,
        mark2: <mark>
                <FormattedMessage id="Onboarding.Upsale.Subtitle3.Mark2" defaultMessage="day FREE trial" />
              </mark>
      }} data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
      </h2>

      <table className={classnames(styles.plansCompareTable, isExtraDiscount && styles.plansCompareTableExtraDiscount)}>
        <thead>
          <tr>
            <th></th>
            <th>
              <FormattedMessage defaultMessage="Current {br} plan" id="Onboarding.Upsale.PlanC" values={{
              br: <br />
            }} data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
            </th>
            <th className={classnames(styles.premiumHeader, isExtraDiscount && styles.premiumHeaderExtraDiscount)}>
              {isExtraDiscount && <div className={styles.premiumExtraDiscountLabel}>25% OFF</div>}
              <span className={styles.premiumLabel}>
                <FormattedMessage defaultMessage="Premium" id="Onboarding.Upsale.PlanPr" data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
              </span>{' '}
              <FormattedMessage defaultMessage="plan" id="Onboarding.Upsale.PlanPrPlan" data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
            </th>
          </tr>
        </thead>

        <tbody>
          {FEATURES.map((feature, index) => <tr className={styles.compareTableBodyRow} key={index}>
              <td>{feature.name}</td>
              <td>
                {feature.includedInCurrent ? <CheckMark style={{
              color: '#356B61'
            }} /> : ''}
              </td>
              <td>
                {feature.includedInPremium ? <CheckMark style={{
              color: '#356B61'
            }} /> : ''}
              </td>
            </tr>)}
        </tbody>

        <tfoot className={styles.priceRow}>
          <tr>
            <td>
              <FormattedMessage defaultMessage="Plan price" id="Onboarding.Premium.Price" data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
            </td>

            <td className={styles.priceCell}>
              <p className={styles.pricePeriod}>
                <FormattedMessage defaultMessage="per" id="Onboarding.Premium.Pper" data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
                <span> {periodUnit}</span>
              </p>
              <SeparatedPrice currency={currency} price={currentPrice} data-sentry-element="SeparatedPrice" data-sentry-source-file="index.tsx" />
            </td>

            <td className={styles.priceCell}>
              <p className={styles.pricePeriod}>
                <FormattedMessage defaultMessage="per" id="Onboarding.Premium.Pper" data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />{' '}
                <span>{periodUnit}</span>
              </p>
              <SeparatedPrice currency={currency} price={premiumCurrentPrice} data-sentry-element="SeparatedPrice" data-sentry-source-file="index.tsx" />
              {premiumOldPrice && <p className={styles.oldPrice}>
                  <FormattedMessage id="Onboarding.Premium.Pperwas" defaultMessage="was" />{' '}
                  {currencyReplacer(currency)}
                  {premiumOldPrice.toFixed(2)}
                </p>}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>;
};