import { useAnalytics } from '@features/Analytics';
import { UpsellSharedModalProps } from '@features/Stripe/widgets/upsell-modal-renderer';
import { FormattedMessage } from '@features/intl';
import { memo, useCallback, useEffect } from 'react';
import { Image } from 'src/components/common/Image';
import Modal from 'src/components/common/modal/modal/modal';
import { UiKitButton } from 'src/components/common/ui-kit-button';
import styles from './upsell-commitment-hard.module.scss';
export const UpsellCommitmentHard = memo(({
  onNext
}: UpsellSharedModalProps) => {
  const {
    trackGoogleEvent
  } = useAnalytics();
  const handleNext = useCallback(() => {
    trackGoogleEvent({
      eventName: 'we-understand_button_continue_click'
    });
    onNext('UPSELL_EXTRA_YEARLY_DISCOUNT_PREMIUM');
  }, [onNext, trackGoogleEvent]);
  useEffect(() => {
    trackGoogleEvent({
      eventName: 'we-understand_screen__loaded'
    });
  }, [trackGoogleEvent]);
  return <Modal className={styles.container} show={true} showCloseButton={false} showMobileHeader={false}>
        <div className={styles.innerContainer}>
          <div className={styles.topPart}>
            <div className={styles.imageWrapper}>
              <Image src="upsell-commitment-hard-image.webp" layout="fill" alt="" />
            </div>
            <h2 className={styles.title}>
              <FormattedMessage defaultMessage="We understand. {br} Commitment is hard." id="Onboarding.Cut.Title" values={{
            br: <br />
          }} />
            </h2>
            <p className={styles.description}>
              <FormattedMessage defaultMessage="We're invested in your success and want to make it a little easier for you." id="Onboarding.Cut.Text1" />
            </p>
            <p className={styles.description}>
              <FormattedMessage defaultMessage="If you choose the yearly plan and start" id="Onboarding.Cut.Text2" />
              <br />
              <FormattedMessage defaultMessage="today, we’ll give an extra {mark}" id="Onboarding.Cut.Text3" values={{
            mark: <mark>
                      <FormattedMessage defaultMessage="15% discount." id="Onboarding.Cut.Text3.Mark" />
                    </mark>
          }} />
            </p>
          </div>

          <div className={styles.bottomPart}>
            <p className={styles.discount}>
              <FormattedMessage defaultMessage="15% off" id="Onboarding.Cut.Above" />
            </p>
            <UiKitButton fullWidth data-testid="commitment-continue-button" className={styles.continueButton} onClick={handleNext}>
              <FormattedMessage defaultMessage="Continue" id="Onboarding.Cut.Button" />
            </UiKitButton>
          </div>
        </div>
      </Modal>;
});
UpsellCommitmentHard.displayName = 'UpsellCommitmentHard';