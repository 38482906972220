export type TpVariants =
  | 'variant15'
  | 'email'
  | 'tw1'
  | 'variantPT'
  | 'google1'
  | 'tiktok1';

export type TpStripeVariants =
  | 'default'
  | 'emailtraf'
  | 'variant16'
  | 'variant17'
  | 'variant18'
  | 'variant20'
  | 'variant21'
  | 'variant21-DEV885'
  | 'variant22'
  | 'TW16'
  | 'PT16'
  | 'google16'
  | 'tiktok16'
  | 'TW17'
  | 'PT17'
  | 'google17'
  | 'tiktok17'
  | 'email2'
  | 'email3'
  | 'test'
  | 'variantsaas'
  | 'variant80'
  | 'variantlong'
  | 'bonus'
  | 'cancellation'
  | 'stressless';

export type TpSource =
  | 'facebook'
  | 'twitter'
  | 'google'
  | 'tiktok'
  | 'pinterest';
export type TpPaymentSystem = 'braintree' | 'paddle' | 'stripe';

export type TpVariantObject = {
  variant_name: TpVariants | TpStripeVariants;
  payment_system: TpPaymentSystem;
  source: TpSource;
};

export const GOOGLE_OR_PINTEREST_SET = new Set([
  'google1',
  'variantPT',
  'PT16',
  'google16',
  'PT17',
  'google17',
]);

export const ALL_VARIANTS: Array<TpVariantObject> = [
  {
    variant_name: 'variant15',
    payment_system: 'braintree',
    source: 'facebook',
  },
  {
    variant_name: 'email',
    payment_system: 'braintree',
    source: 'facebook',
  },
  {
    variant_name: 'tw1',
    payment_system: 'braintree',
    source: 'twitter',
  },
  {
    variant_name: 'variantPT',
    payment_system: 'braintree',
    source: 'pinterest',
  },
  {
    variant_name: 'google1',
    payment_system: 'braintree',
    source: 'google',
  },
  {
    variant_name: 'tiktok1',
    payment_system: 'braintree',
    source: 'tiktok',
  },
  {
    variant_name: 'emailtraf',
    payment_system: 'stripe',
    source: 'facebook',
  },
  {
    variant_name: 'variant16',
    payment_system: 'stripe',
    source: 'facebook',
  },
  {
    variant_name: 'variant17',
    payment_system: 'stripe',
    source: 'facebook',
  },
  {
    variant_name: 'variant18',
    payment_system: 'stripe',
    source: 'facebook',
  },
  {
    variant_name: 'variant20',
    payment_system: 'stripe',
    source: 'facebook',
  },
  {
    variant_name: 'variant21',
    payment_system: 'stripe',
    source: 'facebook',
  },
  {
    variant_name: 'variant21-DEV885',
    payment_system: 'stripe',
    source: 'facebook',
  },
  {
    variant_name: 'variant22',
    payment_system: 'stripe',
    source: 'facebook',
  },
  {
    variant_name: 'TW16',
    payment_system: 'stripe',
    source: 'twitter',
  },
  {
    variant_name: 'PT16',
    payment_system: 'stripe',
    source: 'pinterest',
  },
  {
    variant_name: 'google16',
    payment_system: 'stripe',
    source: 'google',
  },
  {
    variant_name: 'tiktok16',
    payment_system: 'stripe',
    source: 'tiktok',
  },
  {
    variant_name: 'TW17',
    payment_system: 'stripe',
    source: 'twitter',
  },
  {
    variant_name: 'PT17',
    payment_system: 'stripe',
    source: 'pinterest',
  },
  {
    variant_name: 'google17',
    payment_system: 'stripe',
    source: 'google',
  },
  {
    variant_name: 'tiktok17',
    payment_system: 'stripe',
    source: 'tiktok',
  },
  {
    variant_name: 'default',
    payment_system: 'stripe',
    source: 'facebook',
  },
  {
    variant_name: 'email2',
    payment_system: 'stripe',
    source: 'facebook',
  },
  {
    variant_name: 'email3',
    payment_system: 'stripe',
    source: 'facebook',
  },
  {
    variant_name: 'test',
    payment_system: 'stripe',
    source: 'facebook',
  },
  {
    variant_name: 'variantsaas',
    payment_system: 'stripe',
    source: 'facebook',
  },
  {
    variant_name: 'variant80',
    payment_system: 'stripe',
    source: 'facebook',
  },
  {
    variant_name: 'variantlong',
    payment_system: 'stripe',
    source: 'facebook',
  },
  {
    variant_name: 'bonus',
    payment_system: 'stripe',
    source: 'facebook',
  },
  {
    variant_name: 'stressless',
    payment_system: 'stripe',
    source: 'facebook',
  },
  {
    variant_name: 'cancellation',
    payment_system: 'stripe',
    source: 'facebook',
  },
];
