import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';
import { Image } from 'src/components/common/Image';
import { useStores } from 'src/components/common/root-store-provider/root-store-provider';
import { WELCOME_LOADER_TEST_NAME_V2, WelcomeLoaderTestV2CaseName } from 'src/constants/analytics';
import { useProgressSteps } from 'src/utils';
import classNames from 'classnames';
import styles from './welcome-loader.module.scss';
export const MimikaLogoWithText = ({
  className,
  filledLogo
}: {
  className?: string;
  filledLogo?: boolean;
}) => filledLogo ? <>
      <svg className={styles.logoBg} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.8366 19.1009C22.047 13.4363 17.4927 7.73235 14.2435 5.43336C14.1302 5.35323 13.9732 5.37984 13.8844 5.48644C7.73305 12.8703 12.4277 17.439 15.5289 19.1417C15.6284 19.1963 15.7528 19.1774 15.8366 19.1009Z" fill="#FDEDEE" />
        <path d="M15.714 19.063C16.4604 10.6905 9.24843 9.56644 5.29997 10.069C5.16234 10.0866 5.06459 10.2124 5.07123 10.3509C5.53055 19.9503 12.0785 20.143 15.5148 19.3011C15.6251 19.2741 15.7039 19.176 15.714 19.063Z" fill="#F47A87" />
        <path d="M15.6821 19.1707C15.6995 19.1383 15.7105 19.1021 15.7139 19.0643C16.1523 14.147 13.8456 11.73 11.067 10.6743C10.0669 15.0521 13.2341 17.8812 15.5289 19.1411C15.5766 19.1673 15.6301 19.1766 15.6821 19.1707Z" fill="#ECC7CB" />
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" width="73" height="16" viewBox="0 0 73 16" fill="none">
        <path d="M0 0.848805H3.19978L7.78613 10.7798L12.3938 0.848805H15.5723V15.6817H12.4791V7.25729L8.53274 15.6817H7.03951L3.11445 7.25729V15.6817H0V0.848805Z" fill="#302C28" />
        <path d="M20.0084 3.92573C19.5107 3.92573 19.0912 3.76304 18.7499 3.43767C18.4086 3.11229 18.2379 2.70203 18.2379 2.2069C18.2379 1.72591 18.4086 1.32272 18.7499 0.997347C19.0912 0.657825 19.5107 0.488064 20.0084 0.488064C20.5062 0.488064 20.9257 0.657825 21.267 0.997347C21.6083 1.32272 21.779 1.72591 21.779 2.2069C21.779 2.70203 21.6083 3.11229 21.267 3.43767C20.9257 3.76304 20.5062 3.92573 20.0084 3.92573ZM18.3872 5.49602H21.6297V15.6817H18.3872V5.49602Z" fill="#302C28" />
        <path d="M24.4141 5.49602H27.4219V6.72679C27.8628 6.23165 28.3748 5.84969 28.9578 5.5809C29.5409 5.31211 30.1453 5.17772 30.771 5.17772C31.5532 5.17772 32.1789 5.30504 32.6482 5.55968C33.1175 5.80018 33.4873 6.16092 33.7575 6.64191C34.1984 6.17507 34.7317 5.81432 35.3574 5.55968C35.9973 5.30504 36.6302 5.17772 37.2559 5.17772C38.5643 5.17772 39.4958 5.51017 40.0504 6.17507C40.6192 6.83996 40.9037 7.80194 40.9037 9.06101V15.6817H37.8319V9.50663C37.8319 8.85588 37.7039 8.38904 37.4479 8.1061C37.1919 7.82316 36.7795 7.6817 36.2107 7.6817C35.4143 7.6817 34.7743 7.99293 34.2908 8.61539C34.3192 9.06808 34.3335 9.4359 34.3335 9.71883V15.6817H31.2403V9.40053C31.2403 8.77807 31.1266 8.33952 30.899 8.08488C30.6857 7.81609 30.3088 7.6817 29.7684 7.6817C28.9294 7.6817 28.1828 8.15561 27.5286 9.10345V15.6817H24.4141V5.49602Z" fill="#302C28" />
        <path d="M45.235 3.92573C44.7373 3.92573 44.3178 3.76304 43.9765 3.43767C43.6352 3.11229 43.4645 2.70203 43.4645 2.2069C43.4645 1.72591 43.6352 1.32272 43.9765 0.997347C44.3178 0.657825 44.7373 0.488064 45.235 0.488064C45.7328 0.488064 46.1523 0.657825 46.4936 0.997347C46.8349 1.32272 47.0056 1.72591 47.0056 2.2069C47.0056 2.70203 46.8349 3.11229 46.4936 3.43767C46.1523 3.76304 45.7328 3.92573 45.235 3.92573ZM43.6138 5.49602H46.8563V15.6817H43.6138V5.49602Z" fill="#302C28" />
        <path d="M49.6407 0H52.8618V9.80371L56.5949 5.49602H60.328L55.9976 10.4615L60.8613 15.6817H57.1282L52.8618 11.0981V15.6817H49.6407V0Z" fill="#302C28" />
        <path d="M65.7391 16C64.4877 16 63.4637 15.6888 62.6673 15.0663C61.8852 14.4297 61.4941 13.5668 61.4941 12.4775C61.4941 11.3457 61.8709 10.4686 62.6247 9.84615C63.3784 9.20955 64.445 8.89125 65.8244 8.89125C66.436 8.89125 67.019 8.95491 67.5737 9.08223C68.1283 9.20955 68.6189 9.38638 69.0456 9.61273V9.35809C69.0456 8.72149 68.818 8.25464 68.3629 7.95756C67.9079 7.66048 67.211 7.51194 66.2724 7.51194C65.021 7.51194 63.869 7.74536 62.8167 8.2122V5.87798C63.2717 5.67993 63.8619 5.51724 64.5872 5.38992C65.3125 5.24845 66.0378 5.17772 66.7631 5.17772C68.6118 5.17772 69.9913 5.55968 70.9014 6.32361C71.8116 7.07339 72.2667 8.12732 72.2667 9.48541V15.6817H69.2375V14.748C68.37 15.5827 67.2039 16 65.7391 16ZM66.6137 13.878C67.1399 13.878 67.6163 13.779 68.043 13.5809C68.4696 13.3828 68.8038 13.107 69.0456 12.7533V11.7347C68.3203 11.2538 67.5239 11.0133 66.6564 11.0133C66.0591 11.0133 65.5969 11.1406 65.2698 11.3952C64.957 11.6357 64.8005 11.9965 64.8005 12.4775C64.8005 12.9301 64.957 13.2767 65.2698 13.5172C65.5827 13.7577 66.0307 13.878 66.6137 13.878Z" fill="#302C28" />
      </svg>
    </> : <svg className={classNames(styles.mimikaLogo, className)} xmlns="http://www.w3.org/2000/svg" width="143" height="24" viewBox="0 0 143 24" fill="none">
      <g clipPath="url(#clip0_12942_47521)">
        <path d="M17.7086 22.747C27.4658 13.847 20.3105 4.88541 15.2056 1.27339C15.0277 1.14748 14.7809 1.1893 14.6414 1.35678C4.97694 12.9578 12.3527 20.1359 17.2252 22.8111C17.3814 22.8969 17.5769 22.8671 17.7086 22.747Z" fill="#356B61" />
        <path d="M17.5159 22.6889C18.6887 9.5345 7.35779 7.76846 1.15432 8.55812C0.938084 8.58565 0.784515 8.7833 0.794933 9.00103C1.51659 24.0829 11.8042 24.3857 17.2031 23.0629C17.3762 23.0205 17.5001 22.8664 17.5159 22.6889Z" fill="#F47A87" />
        <path d="M17.4658 22.8581C17.4931 22.8073 17.5105 22.7505 17.5158 22.691C18.2046 14.9652 14.5804 11.1678 10.215 9.50919C8.64379 16.3872 13.6198 20.8322 17.2252 22.8117C17.3001 22.8529 17.3842 22.8674 17.4658 22.8581Z" fill="#509487" />
        <path d="M34.4412 1.27101H39.2326L46.1002 16.1418L52.9998 1.27101H57.7592V23.4819H53.1276V10.8671L47.2182 23.4819H44.9822L39.1048 10.8671V23.4819H34.4412V1.27101Z" fill="#302C28" />
        <path d="M64.402 5.87842C63.6567 5.87842 63.0285 5.63481 62.5174 5.14759C62.0063 4.66037 61.7508 4.04604 61.7508 3.30462C61.7508 2.58438 62.0063 1.98065 62.5174 1.49343C63.0285 0.985032 63.6567 0.73083 64.402 0.73083C65.1473 0.73083 65.7755 0.985032 66.2866 1.49343C66.7977 1.98065 67.0532 2.58438 67.0532 3.30462C67.0532 4.04604 66.7977 4.66037 66.2866 5.14759C65.7755 5.63481 65.1473 5.87842 64.402 5.87842ZM61.9744 8.22978H66.8297V23.4819H61.9744V8.22978Z" fill="#302C28" />
        <path d="M70.9991 8.22978H75.503V10.0727C76.1632 9.33132 76.9298 8.75937 77.8029 8.35688C78.676 7.9544 79.581 7.75315 80.518 7.75315C81.6892 7.75315 82.6262 7.9438 83.3289 8.32511C84.0317 8.68523 84.5853 9.2254 84.9899 9.94564C85.6501 9.24659 86.4487 8.70641 87.3856 8.32511C88.3439 7.9438 89.2915 7.75315 90.2285 7.75315C92.1877 7.75315 93.5825 8.25096 94.413 9.24659C95.2648 10.2422 95.6907 11.6827 95.6907 13.568V23.4819H91.091V14.2353C91.091 13.2609 90.8993 12.5618 90.516 12.1381C90.1327 11.7145 89.5151 11.5026 88.6633 11.5026C87.4708 11.5026 86.5125 11.9687 85.7885 12.9007C85.8311 13.5786 85.8524 14.1294 85.8524 14.553V23.4819H81.2207V14.0764C81.2207 13.1443 81.0504 12.4877 80.7097 12.1064C80.3902 11.7039 79.8259 11.5026 79.0167 11.5026C77.7603 11.5026 76.6423 12.2123 75.6627 13.6316V23.4819H70.9991V8.22978Z" fill="#302C28" />
        <path d="M102.176 5.87842C101.431 5.87842 100.803 5.63481 100.292 5.14759C99.7808 4.66037 99.5253 4.04604 99.5253 3.30462C99.5253 2.58438 99.7808 1.98065 100.292 1.49343C100.803 0.985032 101.431 0.73083 102.176 0.73083C102.922 0.73083 103.55 0.985032 104.061 1.49343C104.572 1.98065 104.828 2.58438 104.828 3.30462C104.828 4.04604 104.572 4.66037 104.061 5.14759C103.55 5.63481 102.922 5.87842 102.176 5.87842ZM99.7489 8.22978H104.604V23.4819H99.7489V8.22978Z" fill="#302C28" />
        <path d="M108.774 0H113.597V14.6802L119.187 8.22978H124.777L118.292 15.6652L125.575 23.4819H119.985L113.597 16.6184V23.4819H108.774V0Z" fill="#302C28" />
        <path d="M132.879 23.9585C131.006 23.9585 129.472 23.4925 128.28 22.5604C127.109 21.6071 126.523 20.315 126.523 18.6838C126.523 16.9891 127.087 15.6758 128.216 14.7437C129.344 13.7904 130.942 13.3138 133.007 13.3138C133.923 13.3138 134.796 13.4091 135.627 13.5998C136.457 13.7904 137.192 14.0552 137.831 14.3942V14.0129C137.831 13.0596 137.49 12.3606 136.808 11.9157C136.127 11.4709 135.083 11.2484 133.678 11.2484C131.804 11.2484 130.079 11.598 128.503 12.297V8.80173C129.185 8.50517 130.069 8.26156 131.155 8.0709C132.241 7.85907 133.327 7.75315 134.413 7.75315C137.181 7.75315 139.247 8.32511 140.61 9.46902C141.972 10.5917 142.654 12.1699 142.654 14.2035V23.4819H138.118V22.0838C136.819 23.3336 135.073 23.9585 132.879 23.9585ZM134.189 20.781C134.977 20.781 135.69 20.6327 136.329 20.3361C136.968 20.0396 137.469 19.6265 137.831 19.0969V17.5717C136.744 16.8515 135.552 16.4913 134.253 16.4913C133.359 16.4913 132.667 16.682 132.177 17.0633C131.708 17.4234 131.474 17.9636 131.474 18.6838C131.474 19.3617 131.708 19.8807 132.177 20.2408C132.645 20.6009 133.316 20.781 134.189 20.781Z" fill="#302C28" />
      </g>
      <defs>
        <clipPath id="clip0_12942_47521">
          <rect width="142" height="24" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>;
export const WelcomeLoader = observer(({
  goToNextStep
}: {
  goToNextStep: () => void;
}) => {
  const {
    analyticsStore: {
      getCaseNameByTestName
    }
  } = useStores();
  const [isAnimationFinished, setAnimationFinished] = useState(false);
  const {
    progress
  } = useProgressSteps({
    started: true,
    progressDurations: [500, 2000, 200, 2000, 1300],
    steps: [20, 50, 50, 80, 100]
  });
  const isSecondVariant = getCaseNameByTestName(WELCOME_LOADER_TEST_NAME_V2) === WelcomeLoaderTestV2CaseName.SHOW;
  const updateAnimationShowState = useCallback(async (newState: boolean) => {
    await new Promise(resolve => setTimeout(resolve, 6000));
    setAnimationFinished(newState);
  }, []);
  useEffect(() => {
    updateAnimationShowState(true).then(r => r);
  }, [updateAnimationShowState]);
  useEffect(() => {
    isAnimationFinished && goToNextStep();
  }, [goToNextStep, isAnimationFinished]);
  const renderProgress = useCallback(() => <div className={styles.progressContainer}>
          <div className={styles.progressFill} style={{
      width: `${progress}%`
    }} />
          <span className={styles.progressTitle}>{progress.toFixed(0)}%</span>
        </div>, [progress]);
  return <div className={styles.container}>
        <MimikaLogoWithText />
        {isSecondVariant ? <>
            <h2 className={styles.title}>
              Stress-Free Beauty: Bring Back your Best Look
            </h2>
            <div className={styles.imageWrapper}>
              <Image src="welcome-loading-before-after-v2.webp" alt="" layout="fill" objectFit="contain" />
            </div>
            {renderProgress()}
            <p className={styles.additionalBlock}>
              <span className={styles.loader} />
              Loading the quiz...
            </p>
          </> : <>
            <h2 className={styles.title}>
              Stress-Free Beauty: Bring Back your Fresh Look
            </h2>
            {renderProgress()}
            <p className={styles.additionalBlock}>
              <span className={styles.loader} />
              Loading the quiz...
            </p>
            <div className={styles.imageWrapper}>
              <Image src="welcome-loading-before-after.webp" alt="" layout="fill" objectFit="contain" />
            </div>
          </>}
      </div>;
});