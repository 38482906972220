import classNames from "classnames";
import React, { memo } from "react";
import styles from "src/widgets/policy/components/links.module.scss";
import { useAnalytics } from "@features/Analytics";
import { FormattedMessage, TpMessageKeys } from "@features/intl";
export const TermsLink = memo(({
  place,
  className,
  children
}: {
  className?: string;
  place: "paywall" | "checkout" | "burger_menu" | "email_modal" | "upsale" | "explit_checkout";
  children?: React.ReactNode;
}) => {
  const {
    trackGoogleEvent
  } = useAnalytics();
  let formattedMessageId: TpMessageKeys;
  switch (place) {
    case "burger_menu":
      {
        formattedMessageId = "Onboarding.Menu.Tos";
        break;
      }
    case "paywall":
      {
        formattedMessageId = "Onboarding.Paywall.Main.Contact.Terms.A";
        break;
      }
    case "upsale":
      {
        formattedMessageId = "Onboarding.Book.Terms.A";
        break;
      }
    case "checkout":
      {
        formattedMessageId = "Onboarding.Checkout.Terms.A";
        break;
      }
    default:
      formattedMessageId = "Onboarding.Menu.Tos";
      break;
  }
  return <a className={classNames(styles.link, className)} href="https://legal.mentalgrowth.app/mimika/tos.pdf" target="_blank" rel="noreferrer" onClick={() => trackGoogleEvent({
    eventName: "link_open",
    options: {
      place: place,
      link_name: "terms"
    }
  })}>
        {children || <FormattedMessage id={formattedMessageId} defaultMessage={place === "upsale" || place === "checkout" ? "T&Cs" : "Terms of Service"} />}
      </a>;
});
TermsLink.displayName = 'TermsLink';