import { paymentMethodWithPersistence, premiumYearAllDataAtom, selectedPlanAtom } from '@features/Stripe/atoms';
import { minimalActionTypes, minimalInitialModalState, stripeMinimalModalReducer } from '@features/Stripe/reducers/modal';
import { StripeModalSucceeded } from '@features/Stripe/widgets/modal-succeeded';
import { LimitedUpsell, ModalUpsell, PremiumInfoStaticAlert, UpsellCommitmentHard, UpsellExtraDiscountPremium, UpsellExtraYearlyDiscountPremium, UpsellPremium, UpsellScanner, UpsellYearlyPremium } from '@features/Upsell';
import { useAtomValue } from 'jotai/index';
import React, { memo, useEffect } from 'react';
import { useReducerWithStorage } from 'src/utils/hooks';
type UpsellModalsProps = {
  LIMITED_UPSELL: UpsellSharedModalProps;
  MODAL_UPSELL: UpsellSharedModalProps;
  PREMIUM_INFO_STATIC_ALERT: UpsellSharedModalProps;
  UPSELL_SCANNER: UpsellSharedModalProps;
  UPSELL_COMMITMENT_HARD: UpsellSharedModalProps;
  UPSELL_EXTRA_DISCOUNT_PREMIUM: UpsellSharedModalProps;
  UPSELL_EXTRA_YEARLY_DISCOUNT_PREMIUM: UpsellSharedModalProps;
  UPSELL_PREMIUM: UpsellSharedModalProps;
  UPSELL_YEARLY_PREMIUM: UpsellSharedModalProps;
  SUCCEED: UpsellSharedModalProps;
};
export type UpsellSharedModalProps = {
  onNext: (nextModalId: UpsellModalComponentKey) => void;
  onClose: () => void;
};
const upsellModalComponents: { [K in keyof UpsellModalsProps]: React.ComponentType<UpsellModalsProps[K]> } = {
  LIMITED_UPSELL: LimitedUpsell,
  MODAL_UPSELL: ModalUpsell,
  PREMIUM_INFO_STATIC_ALERT: PremiumInfoStaticAlert,
  UPSELL_SCANNER: UpsellScanner,
  UPSELL_COMMITMENT_HARD: UpsellCommitmentHard,
  UPSELL_EXTRA_DISCOUNT_PREMIUM: UpsellExtraDiscountPremium,
  UPSELL_EXTRA_YEARLY_DISCOUNT_PREMIUM: UpsellExtraYearlyDiscountPremium,
  UPSELL_PREMIUM: UpsellPremium,
  UPSELL_YEARLY_PREMIUM: UpsellYearlyPremium,
  SUCCEED: StripeModalSucceeded
};
export type UpsellModalComponentKey = keyof typeof upsellModalComponents;
type TpModalRendererProps = {
  isUserPaid: boolean;
  isAutoRenewEnabled: boolean;
  isUserLoginedByWinback: boolean;
  isNewUpsellsTest: boolean;
};
const determineInitialModal = ({
  isUserPaid,
  isAutoRenewEnabled,
  isUserLoginedByWinback,
  isPayedWithBraintree,
  wasShown,
  isNewUpsellsAvailable
}: {
  isUserPaid: boolean;
  isAutoRenewEnabled: boolean;
  isUserLoginedByWinback: boolean;
  isPayedWithBraintree: boolean;
  wasShown: Record<UpsellModalComponentKey, boolean>;
  isNewUpsellsAvailable: boolean;
}): UpsellModalComponentKey | null => {
  if (isUserPaid && isUserLoginedByWinback || wasShown['SUCCEED']) {
    return 'SUCCEED';
  }
  if (isUserPaid && isNewUpsellsAvailable && !isPayedWithBraintree && !isAutoRenewEnabled && !wasShown['UPSELL_PREMIUM']) {
    return 'UPSELL_PREMIUM';
  }
  if (isUserPaid && (isPayedWithBraintree || !isNewUpsellsAvailable) && !isAutoRenewEnabled && !wasShown['LIMITED_UPSELL']) {
    return 'LIMITED_UPSELL';
  }
  if (isUserPaid && isAutoRenewEnabled && !wasShown['MODAL_UPSELL']) {
    return 'MODAL_UPSELL';
  }
  return null;
};
export const ModalRenderer = memo(({
  isUserPaid,
  isAutoRenewEnabled,
  isNewUpsellsTest,
  isUserLoginedByWinback
}: TpModalRendererProps) => {
  const [minimalModalState, minimalModalDispatch] = useReducerWithStorage(minimalInitialModalState, stripeMinimalModalReducer);
  const {
    currentModal
  } = minimalModalState;
  const paymentMethod = useAtomValue(paymentMethodWithPersistence);
  const {
    premiumDiscountPriceData,
    premiumPriceData
  } = useAtomValue(selectedPlanAtom);
  const {
    premiumYearDiscountData,
    premiumYearData
  } = useAtomValue(premiumYearAllDataAtom);
  const isAllNewUpsellsAvailable = !!premiumPriceData && premiumPriceData.fullPrice != 0 && !!premiumDiscountPriceData && premiumDiscountPriceData.fullPrice != 0 && premiumYearDiscountData.fullPrice != 0 && premiumYearData.fullPrice != 0 && isNewUpsellsTest;
  const isPayedWithBraintree = paymentMethod === 'paypal';
  useEffect(() => {
    const initialModal = determineInitialModal({
      isUserPaid,
      isAutoRenewEnabled,
      isUserLoginedByWinback,
      isPayedWithBraintree,
      wasShown: minimalModalState.wasShown || {},
      isNewUpsellsAvailable: isAllNewUpsellsAvailable
    });
    if (initialModal && !currentModal) {
      minimalModalDispatch({
        type: minimalActionTypes.SHOW_MODAL,
        payload: {
          modalId: initialModal
        }
      });
    }
  }, [isUserPaid, currentModal, isAutoRenewEnabled, isUserLoginedByWinback, isPayedWithBraintree, minimalModalState.wasShown, isAllNewUpsellsAvailable, minimalModalDispatch]);
  if (!currentModal) return null;
  const ModalComponent = upsellModalComponents[currentModal];
  const modalProps = ({
    onNext: (nextModalId: UpsellModalComponentKey) => minimalModalDispatch({
      type: minimalActionTypes.SHOW_MODAL,
      payload: {
        modalId: nextModalId
      }
    }),
    onClose: () => minimalModalDispatch({
      payload: {
        modalId: null
      },
      type: minimalActionTypes.HIDE_MODAL
    })
  } as UpsellModalsProps[typeof currentModal]);
  return <ModalComponent {...modalProps} />;
});